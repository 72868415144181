@import url("reset.css");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap");

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  font-weight: 300;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: 200;
  font-size: 5rem;
}

h2 {
  font-weight: 400;
  font-size: 3rem;
}

h3 {
  font-weight: 200;
  font-size: 2.5rem;
}

p {
  margin-bottom: 1.5em;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}
